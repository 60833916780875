<template>
  <b-row>
    <b-col cols="3">
      <TreeView2></TreeView2>
    </b-col>
    <b-col cols="9">
      <b-card no-body>
        <b-card-body class="p-3">
          <h5 class="card-title mb-0">공지사항 목록</h5>
        </b-card-body>

        <b-table
          responsive
          class="mb-0 p-3"
          head-variant="light"
          :items="items"
          :current-page="currentPage"
          :per-page="perPage"
          :fields="fields"
        >
        </b-table>

        <div class="row p-3 m-0 d-flex justify-content-between">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            class="my-0"
          ></b-pagination>
          <b-button variant="outline-info" @click="$router.push('/noticeWrite')">글쓰기</b-button>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.b-table {
  border: 1px solid #eee
}
</style>

<script>
import TreeView2 from '../components/apps/TreeView2';
export default {
  name: "Notice",

  data: () => ({
    page: {
      title: "PaginationTable",
    },
    fields: [
      {
        key: "category",
        label: "항목",
      },
      {
        key: "title",
        label: "제목",
      },
      {
        key: "upload_start",
        label: "공지일",
      },
      {
        key: "upload_end",
        label: "공지기한",
      }
    ],
    items: [
        {idx: "1", category: "공사/보수", title: "공용부 대리석 작업 안내4", upload_start: "22.11.21", upload_end: "22.12.19"},
        {idx: "3", category: "공사/보수", title: "공용부 대리석 작업 안내3", upload_start: "22.11.04", upload_end: "22.11.18"},
        {idx: "4", category: "공사/보수", title: "공용부 대리석 작업 안내2", upload_start: "22.10.16", upload_end: "22.11.01"},
        {idx: "5", category: "공사/보수", title: "공용부 대리석 작업 안내1", upload_start: "22.09.19", upload_end: "22.10.11"},
        {idx: "6", category: "생활안내", title: "9월 안내문", upload_start: "22.09.01", upload_end: "22.09.30"},
        {idx: "7", category: "생활안내", title: "8월 안내문", upload_start: "22.08.01", upload_end: "22.08.13"},
        {idx: "8", category: "생활안내", title: "7월 안내문", upload_start: "22.07.01", upload_end: "22.07.30"},
        {idx: "9", category: "생활안내", title: "6월 안내문", upload_start: "22.06.01", upload_end: "22.06.30"},
        {idx: "10", category: "생활안내", title: "5월 안내문", upload_start: "22.05.01", upload_end: "22.05.30"},
        {idx: "11", category: "생활안내", title: "4월 안내문", upload_start: "22.04.01", upload_end: "22.04.30"},
        {idx: "12", category: "생활안내", title: "3월 안내문", upload_start: "22.03.01", upload_end: "22.03.30"},
        {idx: "13", category: "생활안내", title: "2월 안내문", upload_start: "22.02.01", upload_end: "22.02.28"},
        {idx: "14", category: "생활안내", title: "1월 안내문", upload_start: "22.01.01", upload_end: "22.01.30"},
        {idx: "15", category: "기타", title: "입주자 공지사항", upload_start: "21.12.20", upload_end: "21.01.27"},
        {idx: "16", category: "행사", title: "놀이터 이용안내", upload_start: "21.11.24", upload_end: "22.09.23"},
        {idx: "17", category: "기타", title: "관리사무소 휴가철 안전관리 공지", upload_start: "21.08.03", upload_end: "21.09.01"},
        {idx: "18", category: "행사", title: "베란다 텃밭 가꾸기 교육", upload_start: "21.05.19", upload_end: "21.05.22"},
        {idx: "19", category: "기타", title: "주차 관련 공지", upload_start: "21.03.22", upload_end: "21.08.21"},
        {idx: "20", category: "회계보고", title: "협조문", upload_start: "21.02.04", upload_end: "21.02.05"}
    ],
    totalRows: 0,
    currentPage: 1,
    perPage: 10
  }),
  components: {TreeView2},
  methods: {

  },
  mounted() {
    this.totalRows = this.items.length;
  },
};
</script>
