<template>
    <b-card class="treeCard" no-body>
        <b-card-body class="p-3">
            <h5 class="card-title mb-0">항목별 관리</h5>
          </b-card-body>
        <ul id="demo" style="max-height: 250px; overflow-y: auto">
            <treeItem
                class="item"
                :setDong="() => {}"
                :item="treeData"
            ></treeItem>
        </ul>
    </b-card>    
</template>

<script>
import treeItem from './ItemComponent'
export default {
    name:"TreeView2",
    props: {
        setDong: Function
    },
    components:{
        treeItem
    },
    data:() =>( {
        treeData: {
            name: "전체",
            children: [
                {name: "회계보고"},
                {name: "공사/보수"},
                {name: "행사"},
                {name: "생활안내"},
                {name: "기타"}
            ]
        }
    }),
     methods: {

    }
}
</script>

<style lang="scss">
.treeCard{
.item {
  cursor: pointer;
}
.bold {
  font-weight: bold;
}
ul {
    margin:0px;
    padding:0 15px;
    list-style: none;
    li{
        padding: 7px;
    }
}
}
</style>